<template>
  <div>
    <SectionHeading
      :content="heading.content"
      :extra_content="heading.extra"
      @search="onSearch"
      @btnClick="onNewRowClick"
    />
    <Table
      :search="search"
      :headers="headers"
      :items="rows"
      @edit="onRowEdit"
      @remove="onRowRemove"
    />

    <v-dialog width="450" v-model="addDialog">
      <v-card>
        <v-card-title>{{ dialogHeader }}</v-card-title>

        <v-divider />

        <v-card-text>
          <v-form class="mt-5">
            <v-text-field
              label="Database Name"
              v-model="database.name"
              prepend-icon="mdi-database-search-outline"
            />
            <v-text-field
              label="Upload Date"
              v-model="database.leak_date"
              prepend-icon="mdi-database-clock-outline"
            />
            <v-text-field
              label="Leak Date"
              v-model="database.upload_date"
              prepend-icon="mdi-database-arrow-right-outline"
            />
            <v-file-input
              label="Database File"
              prepend-icon="mdi-database-outline"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDialog" text> Cancel </v-btn>
          <v-btn color="primary" text>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SectionHeading from "../../components/SectionHeading.vue";
import Table from "../components/Table.vue";
export default {
  name: "Databases",

  components: {
    Table,
    SectionHeading,
  },

  methods: {
    closeDialog() {
      this.addDialog = false;
      this.database = {
        id: null,
        name: null,
        upload_date: null,
        leak_date: null,
        file: null,
      };
    },

    onSearch(e) {
      this.search = e;
    },

    onNewRowClick() {
      this.dialogHeader = "Create Database";
      this.addDialog = true;
    },

    onRowEdit(id) {
      this.dialogHeader = "Edit Database";
      this.database = this.rows[id];
      this.addDialog = true;
    },

    onRowRemove(id) {
      console.log(id);
    },
  },

  data() {
    return {
      addDialog: false,
      dialogHeader: null,
      deleteDialog: false,
      database: { name: null, upload_date: null, leak_date: null },
      heading: {
        content: "Databases",
        extra: [
          {
            type: "button",
            text: "Create Row",
            color: "#3498DB",
            font_color: "#FFFFFF",
            icon: "mdi-database-plus-outline",
          },
          { type: "search" },
        ],
      },

      search: "",

      headers: [
        {
          text: "Database Name",
          align: "start",
          value: "name",
        },
        { text: "Upload Date", value: "upload_date" },
        { text: "Leak Date", value: "leak_date" },
        { text: "Rows", value: "rows" },
        { text: "Actions", value: "actions" },
      ],
      rows: [
        {
          id: 0,
          name: "GMAIL_LEAK_2018",
          upload_date: "27/01/2022 14:54",
          leak_date: "20/08/2008 18:22",
          rows: "78921",
        },
      ],
    };
  },
};
</script>

<style lang='scss'>
</style>